import { notification } from 'antd';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import modalActions from 'redux/modal/actions';
import {
  deleteRack,
  fetchRackScan,
  fetchRackScans,
  updateRackScan,
  uploadScan,
  createSessionFromRackScan,
  createSessionFromRackScans,
  createSessionFromMapFile,
} from 'services/racks';
import labConfig from 'utils/labConfig';
import actions from './actions';

export function* callFetchRacks({ payload }) {
  try {
    const { data } = yield call(fetchRackScans, payload);

    yield put({
      type: actions.FETCH_RACKS_SUCCESS,
      payload: {
        data: data?.results ?? [],
        total: data.count,
        firstPage: !data.previous,
      },
    });
  } catch (error) {
    yield put({ type: actions.FETCH_RACKS_FAILURE });
    notification.error({ message: error.message, duration: 10 });
  }
}

export function* callFetchRack({ payload }) {
  try {
    const { data } = yield call(fetchRackScan, payload);

    const formatResponse = (response) => {
      return Object.assign(
        {},
        ...response?.map?.((obj) => ({
          letter: obj?.position?.[0],
          [`col${obj?.position?.substr(1)}`]: {
            ...obj,
            status: obj?.status,
          },
        })),
      );
    };

    const tubesInfo = data?.tubes;

    const preparedResponse = [
      formatResponse(tubesInfo?.slice?.(0, 8)),
      formatResponse(tubesInfo?.slice?.(8, 16)),
      formatResponse(tubesInfo?.slice?.(16, 24)),
      formatResponse(tubesInfo?.slice?.(24, 32)),
      formatResponse(tubesInfo?.slice?.(32, 40)),
      formatResponse(tubesInfo?.slice?.(40, 48)),
    ];

    yield put({
      type: actions.GET_RACK_SUCCESS,
      payload: { ...data, items: preparedResponse },
    });
  } catch (error) {
    yield put({ type: actions.GET_RACK_FAILURE });
    notification.error({ message: error.message, duration: 10 });
  }
}

export function* callUpdateRack({ payload }) {
  const { callback, ...data } = payload;
  try {
    const response = yield call(updateRackScan, data);

    yield put({
      type: actions.UPDATE_RACK_SUCCESS,
      payload: response.data,
    });

    yield put({ type: modalActions.HIDE_MODAL });

    notification.success({
      message: `${labConfig[process.env.REACT_APP_LAB_ID].naming.rack} updated`,
    });

    if (payload.callback) {
      payload.callback();
    }
  } catch (error) {
    yield put({ type: actions.UPDATE_RACK_FAILURE });
    notification.error({ message: error.message, duration: 10 });
  }
}

export function* callDeleteRack({ payload }) {
  try {
    yield call(deleteRack, payload);

    yield put({
      type: actions.DELETE_RACK_BY_ID_SUCCESS,
      payload: {
        id: payload.id,
      },
    });

    notification.success({
      message: 'Rack was deleted successfully!',
    });
  } catch (error) {
    yield put({ type: actions.DELETE_RACK_BY_ID_FAILURE });
    notification.error({ message: error.message, duration: 10 });
  }
}

export function* callUploadScan({ payload }) {
  const { options, refetchRacks } = payload;
  try {
    yield call(uploadScan, options);

    yield put({ type: actions.UPLOAD_SCAN_SUCCESS });

    yield put({ type: modalActions.HIDE_MODAL });

    yield call(refetchRacks);

    notification.success({ message: 'Scan uploaded successfully' });
  } catch (error) {
    yield put({ type: actions.UPLOAD_SCAN_FAILURE });
    notification.error({ message: error.message, duration: 10 });
  }
}

export function* callCreateSessionFromRackscan({ payload }) {
  try {
    const { data } = payload;
    const response = yield call(createSessionFromRackScan, { ...data });

    yield put({ type: actions.CREATE_SESSION_FROM_RACKSCAN_SUCCESS });

    yield put({ type: modalActions.HIDE_MODAL });

    notification.success({ message: response.data });
  } catch (error) {
    yield put({ type: actions.CREATE_SESSION_FROM_RACKSCAN_FAILURE });
    notification.error({ message: error.message });
  }
}

export function* callCreateSessionFromRackscans({ payload }) {
  try {
    const response = yield call(createSessionFromRackScans, { ...payload });

    yield put({ type: actions.CREATE_SESSION_FROM_RACKSCANS_SUCCESS });

    notification.success({ message: response.data });
  } catch (error) {
    yield put({ type: actions.CREATE_SESSION_FROM_RACKSCANS_FAILURE });
    notification.error({ message: error.message });
  }
}

export function* callCreateSessionFromMapFile({ payload }) {
  try {
    const response = yield call(createSessionFromMapFile, { ...payload });

    yield put({ type: actions.CREATE_SESSION_FROM_MAPFILE_SUCCESS });

    notification.success({ message: response.data });
  } catch (error) {
    yield put({ type: actions.CREATE_SESSION_FROM_MAPFILE_FAILURE });
    notification.error({ message: error.message });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.FETCH_RACKS_REQUEST, callFetchRacks),
    takeEvery(actions.GET_RACK_REQUEST, callFetchRack),
    takeEvery(actions.UPDATE_RACK_REQUEST, callUpdateRack),
    takeEvery(actions.DELETE_RACK_BY_ID_REQUEST, callDeleteRack),
    takeEvery(actions.UPLOAD_SCAN_REQUEST, callUploadScan),
    takeEvery(
      actions.CREATE_SESSION_FROM_RACKSCAN_REQUEST,
      callCreateSessionFromRackscan,
    ),
    takeEvery(
      actions.CREATE_SESSION_FROM_RACKSCANS_REQUEST,
      callCreateSessionFromRackscans,
    ),
    takeEvery(
      actions.CREATE_SESSION_FROM_MAPFILE_REQUEST,
      callCreateSessionFromMapFile,
    ),
  ]);
}
